/* eslint-disable react/jsx-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable quotes */
import { client, dataCollector } from "braintree-web";
import Fingerprint from "fingerprintjs2";
import PropTypes from "prop-types";
import publicIp from "public-ip";
import queryString from "query-string";
import React, { Component } from "react";
import Cards from "react-credit-cards";
import { geolocated } from "react-geolocated";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Media,
  Row,
} from "reactstrap";
import { bindActionCreators } from "redux";
import { postDataCard } from "../../actions/card";
import { postDataCheckout } from "../../actions/checkout";
import { getCheckout } from "../../actions/resume";
import CHECKOUT from "../../const/checkout";
import Footer from "../../containers/defaultFooter";
import Header from "../../containers/defaultHeader";
import Braintree from "../../lib/braintree";
import {
  cardDateValidator,
  cpfValidator,
  dateOfBirthValidator,
} from "../../lib/customValidade";
import eventEmitter from "../../lib/eventEmitter";
import {
  CpfMask,
  CreditCardMask,
  DateBirthMask,
  DateValidateMask,
} from "../../lib/mask";
import { modelToViewModel as cardModelToViewModel } from "../../mapper/card";
import { modelToViewModel } from "../../mapper/checkout";
import { modelToFilterDetail } from "../../mapper/resume";
import Model from "../../model/checkout";
import TokenModel from "../../model/token";
import Cookies from "../utils/cookies";
import Loading from "../utils/loading";

class Checkout extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmitTokenizeCard = this.handleSubmitTokenizeCard.bind(this);
    this.handleSubmitConfirmPayment =
      this.handleSubmitConfirmPayment.bind(this);
    this.state = {
      ...Model,
      TokenRequest: TokenModel,
      CardRequest: "",
      Redirected: false,
      Finger: "",
      IpAddress: "",
      Requested: false,
      CardSection: false,
      CardTonenized: null,
      FinishSection: false,
      Width: window.innerWidth,
    };

    const checkoutToken = modelToFilterDetail(props.match.params);
    props.getCheckout(checkoutToken);

    Fingerprint.getPromise().then((result) => {
      this.setState({ Finger: result });
    });

    publicIp.v4().then((result) => {
      this.setState({ IpAddress: result });
    });
  }

  async componentDidMount() {
    const token = await Braintree().clientToken.generate();

    const clientCreated = await client.create({
      authorization: token.clientToken,
    });

    const data = await dataCollector.create({ client: clientCreated });

    this.setState({ DeviceData: data.deviceData });
  }

  async handleSubmitTokenizeCard(event) {
    event.preventDefault();

    eventEmitter.clear("error");
    const p = this.props;
    this.setState({ Requested: true });

    const creditCardRequest = cardModelToViewModel(this.state, p.listResume);
    const creditCardResponse = await p.postDataCard(
      creditCardRequest,
      p.match.params.token
    );
    this.setState({
      CardSection: !creditCardResponse.success,
      CardTonenized: false,
      Requested: false,
      FinishSection: creditCardResponse.success,
      CardRequest: creditCardResponse,
    });
  }

  async handleSubmitConfirmPayment(event) {
    event.preventDefault();

    eventEmitter.clear("error");

    const p = this.props;
    const { DeviceData } = this.state;
    this.setState({ Requested: true });

    const creditCardRequest = cardModelToViewModel(this.state, p.listResume);

    const confirmPaymentRequest = modelToViewModel(
      this.state,
      p.listResume,
      p.coords,
      creditCardRequest
    );

    p.postDataCheckout(confirmPaymentRequest, p.match.params.token, DeviceData);

    this.setState({
      CardSection: true,
      Requested: false,
      Redirected: true,
    });
  }

  render() {
    cpfValidator();
    cardDateValidator();
    dateOfBirthValidator();

    const { statePostCheckout, listResume, location } = this.props;

    const {
      CreditCardName,
      CreditCardNameValid,
      DateOfBirth,
      DateOfBirthValid,
      Document,
      DocumentValid,
      CreditCardNumber,
      CreditCardNumberValid,
      Cvv,
      CvvValid,
      DateValidate,
      DateValidateValid,
      CreditCardId,
      Redirected,
      Focus,
      Requested,
      CardSection,
      Width,
      FinishSection,
      CardTonenized,
    } = this.state;

    const isMobile = Width <= 999;
    const query = queryString.parse(location.search);

    const requireCVV = (query.requireCVV || "true") === "true";

    const model = listResume.data;

    if (listResume && listResume.state !== false) {
      return <Loading msg={CHECKOUT.LOADING} />;
    }

    if ((!listResume || !listResume.data) && listResume.success === true) {
      return <Loading msg={CHECKOUT.LOADING} />;
    }

    if (!listResume.data && listResume.success === false) {
      return <Redirect to={{ pathname: "/invalid-token" }} />;
    }

    if (listResume.data && listResume.data.Status === "CANCELLED") {
      return <Redirect to={{ pathname: "/invalid-token" }} />;
    }

    if (
      listResume.data &&
      listResume.data.Status === "IN_PROGRESS" &&
      listResume.data.NotChangePaymentMethod
    ) {
      return <Redirect to={{ pathname: "/resume", state: { listResume } }} />;
    }

    if (
      statePostCheckout &&
      statePostCheckout.state === false &&
      Redirected &&
      statePostCheckout.success === true
    ) {
      return <Redirect to={{ pathname: "/resume", state: { listResume } }} />;
    }

    const errors = eventEmitter.getByName("error");
    const cardSelectionOpen =
      (model.VoucherStatus === "CONFIRMED" || model.OnlyCreditCard) &&
      !FinishSection;
    const actualCycle = model.InstallmentCount - model.Remaining.Installments;
    const actualParcel = model && model.BillingCycles[actualCycle];
    const statusChangePayment =
      model &&
      model.Status !== "CREATED" &&
      (actualParcel.Status === "PENDING" ||
        actualParcel.Status === "PROCESSING_ERROR" ||
        actualParcel.Status === "PROCESSING_DELEGATION_ERROR" ||
        actualParcel.Status === "PAYMENT_DENIED" ||
        actualParcel.Status === "PAYMENT_ERROR");

    const installmentsDetails =
      model.InstallmentValue && model.ShowParcelInfo
        ? model.InstallmentValue.BRLCurrency()
        : `${model.Remaining.Amount.BRLCurrency()} (${
            model.Remaining.Installments
          }x de ${model.InstallmentValue.BRLCurrency()})`;
    const total =
      statusChangePayment && model.InstallmentValue && model.ShowParcelInfo
        ? model.TotalPrize.BRLCurrency()
        : model.Remaining.Amount.BRLCurrency();

    return (
      <div
        className={`container-page ${isMobile ? "container-payment" : ""}${
          (cardSelectionOpen || CardSection) && model.BalancePay > 0
            ? "container-auto"
            : ""
        }`}
      >
        <Header listResume={listResume} />
        <Cookies />
        <Row className="m-0 h-150">
          <Col md="9">
            <div className="app back-logo flex-row align-items-center mb-0">
              <Container>
                <Row className="justify-content-center">
                  <Col md="12" className="mb-3">
                    <CardGroup>
                      <Card
                        className="pt-4 pl-4 pr-4 pb-3 rounded-0"
                        style={{
                          boxShadow: "1px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <Row>
                          <Col md="12" className="float-left">
                            <Label className="font-xl mb-3">
                              Olá,
                              <strong className="font-xl">
                                &nbsp;
                                {model.FullName}
                              </strong>
                              !
                            </Label>
                            {statusChangePayment ? (
                              <>
                                <br />
                                <Label className="font-xl mb-3">
                                  Atualize os dados do seu cartão de crédito
                                  para regularizar o pagamento do seu seguro.
                                </Label>
                              </>
                            ) : (
                              <>
                                <Label className="font-xl mb-3">
                                  A seguradora já nos enviou as informações da
                                  sua proposta e elas estão aqui para que você
                                  possa conferir e finalizar a compra.
                                </Label>
                                <Label className="font-xl mb-0">
                                  {`Como foi acordado com seu corretor, ${
                                    !model.ShowParcelInfo
                                      ? "o restante do"
                                      : "o"
                                  } pagamento será feito em `}
                                  <b>
                                    {model.ShowParcelInfo
                                      ? `parcelas ${
                                          model.RecurringUnitInfo
                                        } de ${model.InstallmentValue.BRLCurrency()}`
                                      : `${model.BalancePay.BRLCurrency()} (${
                                          model.InstallmentCount
                                        }x de ${model.InstallmentValue.BRLCurrency()}) `}
                                  </b>
                                  no seu cartão de crédito na modalidade de
                                  recorrência.
                                </Label>
                              </>
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </CardGroup>
                  </Col>
                  <Col
                    md="3"
                    id="ssl-mobile"
                    className="mb-3"
                    style={{
                      width: "108%",
                      marginLeft: "-14px",
                      marginRight: "-14px",
                      backgroundColor: "#f5f5f5",
                      display: "none",
                    }}
                  >
                    <Row className="m-0 mt-5">
                      <Col className="flex-column d-flex align-items-center">
                        <Media
                          object
                          src={model.IconType}
                          alt="Logo"
                          className="mt-3 mb-2"
                          style={{ maxWidth: "130px" }}
                        />
                        <Label className="text-center border-bottom w-100 m-0 pb-3">
                          <strong style={{ color: "#58595b" }}>
                            {model.TextType}
                          </strong>
                        </Label>
                      </Col>
                    </Row>
                    <Row className="m-0">
                      <Col md="12" className="pb-2 mt-3 mb-3">
                        <Label>
                          <strong className="label-side-info">
                            Dados da proposta
                          </strong>
                        </Label>
                      </Col>
                    </Row>
                    {model.Cars &&
                      model.Type === "AUTO" &&
                      model.Cars.map((car, idx) => (
                        <Row className="m-0">
                          <Col md="12" key={car.licensePlate}>
                            <span className="float-left pr-2 label-side-info">
                              {`${CHECKOUT.DETAILS.VEHICLE_PLATE} #${idx + 1}:`}
                            </span>
                            <span className="float-left fw-600 label-side-info">
                              {car.licensePlate}
                            </span>
                          </Col>
                        </Row>
                      ))}
                    <Row className="m-0">
                      <Col md="12">
                        <span className="float-left pr-2 label-side-info">
                          {CHECKOUT.DETAILS.PROPOSAL_NUMBER}
                        </span>
                        <span
                          className={`float-left fw-600 label-side-info pb-4 w-100 ${
                            model.Type === "AUTO" && "border-bottom"
                          }`}
                        >
                          {model.ProposalNumber}
                        </span>
                      </Col>
                    </Row>
                    {model.Type !== "AUTO" && (
                      <Col md="12">
                        <Label className="label-side-info pb-4 border-bottom w-100">
                          {model.Document.length === 10
                            ? CHECKOUT.DETAILS.CNPJ
                            : CHECKOUT.DETAILS.CPF}
                          <strong className="ml-2 label-side-info">
                            {model.Document}
                          </strong>
                        </Label>
                      </Col>
                    )}
                    <Row className="m-0">
                      <Col md="12" className="pb-2 pt-3 label-side-info mt-3">
                        <Label>
                          <strong className="label-side-info">
                            {CHECKOUT.DETAILS.VALUE}
                          </strong>
                        </Label>
                      </Col>
                    </Row>
                    <Row className="m-0">
                      <Col md="12">
                        <Label className="label-side-info pb-4 border-bottom w-100">
                          {CHECKOUT.DETAILS.BALANCE_PAY}
                          <strong className="ml-2 label-side-info">
                            {installmentsDetails}
                          </strong>
                        </Label>
                      </Col>
                    </Row>
                    {!model.ShowParcelInfo && (
                      <Row className="m-0 mt-3 mb-4">
                        <Col md="12">
                          <span className="float-left pr-2 label-side-info">
                            {CHECKOUT.DETAILS.TOTAL_PRIZE}
                          </span>
                          <span className="float-left fw-600 label-side-info">
                            {total}
                          </span>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col md="12">
                    <CardGroup>
                      {(cardSelectionOpen || CardSection) &&
                        model.BalancePay > 0 && (
                          <>
                            <Col md="12 d-flex align-items-center">
                              <Media
                                object
                                src="assets/icons/credit-card-icon.svg"
                                alt="Logo"
                              />
                              <Label className="mb-0 ml-2">
                                <strong className="font-xl">
                                  {CHECKOUT.FORM.TITLE_CARD_SECTION}
                                </strong>
                              </Label>
                            </Col>
                          </>
                        )}
                      <Form
                        onSubmit={this.handleSubmitConfirmPayment}
                        style={{ width: "100%" }}
                      >
                        <Card
                          className="p-4 rounded-0 m-0"
                          style={{
                            boxShadow: "1px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          <Row>
                            <CardBody className="pb-0">
                              {(cardSelectionOpen || CardSection) &&
                              model.BalancePay > 0 ? (
                                <FormGroup row className="my-0">
                                  <Col md="6">
                                    <Col xs="9">
                                      <FormGroup>
                                        <Input
                                          type="text"
                                          required="required"
                                          maxLength="19"
                                          minLength="14"
                                          id="CreditCardNumber"
                                          name="CreditCardNumber"
                                          value={CreditCardNumber}
                                          valid={CreditCardNumberValid}
                                          invalid={
                                            CreditCardNumberValid === false
                                          }
                                          disabled={Requested}
                                          placeholder={
                                            CHECKOUT.FORM
                                              .CREDIT_CARD_NUMBER_PLACEHOLDER
                                          }
                                          onChange={(e) =>
                                            this.setState({
                                              CreditCardNumber: CreditCardMask(
                                                e.target.value
                                              ),
                                              CreditCardNumberValid:
                                                e.target.validity.valid,
                                            })
                                          }
                                          onFocus={(e) =>
                                            this.setState({
                                              Focus: e.target.name,
                                            })
                                          }
                                          className="inputs-card-data mb-5"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                      <FormGroup>
                                        <Input
                                          type="text"
                                          required="required"
                                          maxLength="36"
                                          id="CreditCardName"
                                          name="CreditCardName"
                                          value={CreditCardName}
                                          valid={CreditCardNameValid}
                                          invalid={
                                            CreditCardNameValid === false
                                          }
                                          disabled={Requested}
                                          placeholder={
                                            CHECKOUT.FORM.NAME_PLACEHOLDER
                                          }
                                          onChange={(e) =>
                                            this.setState({
                                              CreditCardName: e.target.value,
                                              CreditCardNameValid:
                                                e.target.validity.valid,
                                            })
                                          }
                                          onFocus={(e) =>
                                            this.setState({
                                              Focus: e.target.name,
                                            })
                                          }
                                          className="inputs-card-data mb-4"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Row className="m-0">
                                      <Col xs="6">
                                        <FormGroup>
                                          <Input
                                            type="text"
                                            required="required"
                                            maxLength="10"
                                            minLength="10"
                                            id="DateOfBirth"
                                            name="DateOfBirth"
                                            value={DateOfBirth}
                                            valid={DateOfBirthValid}
                                            invalid={DateOfBirthValid === false}
                                            disabled={Requested}
                                            placeholder={
                                              CHECKOUT.FORM
                                                .DATE_BIRTH_PLACEHOLDER
                                            }
                                            className="date-of-birth-validator inputs-card-data mt-4 mb-4"
                                            onChange={(e) =>
                                              this.setState({
                                                DateOfBirth: DateBirthMask(
                                                  e.target.value
                                                ),
                                                DateOfBirthValid:
                                                  e.target.validity.valid,
                                              })
                                            }
                                            onFocus={(e) =>
                                              this.setState({
                                                Focus: e.target.name,
                                              })
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col xs="6">
                                        <FormGroup>
                                          <Input
                                            type="text"
                                            required="required"
                                            maxLength="14"
                                            minLength="14"
                                            id="Document"
                                            name="Document"
                                            value={Document}
                                            valid={DocumentValid}
                                            invalid={DocumentValid === false}
                                            disabled={Requested}
                                            placeholder={
                                              CHECKOUT.FORM.DOCUMENT_PLACEHOLDER
                                            }
                                            className="cpf-validator inputs-card-data mt-4 mb-4"
                                            onChange={(e) =>
                                              this.setState({
                                                Document: CpfMask(
                                                  e.target.value
                                                ),
                                                DocumentValid:
                                                  e.target.validity.valid,
                                              })
                                            }
                                            onKeyUp={(e) =>
                                              this.setState({
                                                Document: CpfMask(
                                                  e.target.value
                                                ),
                                                DocumentValid:
                                                  e.target.validity.valid,
                                              })
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row className="m-0">
                                      <Col md="6">
                                        <FormGroup>
                                          <Input
                                            type="text"
                                            required="required"
                                            maxLength="7"
                                            minLength="7"
                                            id="DateValidate"
                                            name="DateValidate"
                                            value={DateValidate}
                                            valid={DateValidateValid}
                                            invalid={
                                              DateValidateValid === false
                                            }
                                            disabled={Requested}
                                            placeholder={
                                              CHECKOUT.FORM
                                                .DATE_VALIDATE_PLACEHOLDER
                                            }
                                            className="card-date-validator inputs-card-data mt-4"
                                            onChange={(e) =>
                                              this.setState({
                                                DateValidate: DateValidateMask(
                                                  e.target.value
                                                ),
                                                DateValidateValid:
                                                  e.target.validity.valid,
                                              })
                                            }
                                            onFocus={(e) =>
                                              this.setState({
                                                Focus: e.target.name,
                                              })
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                      {requireCVV && (
                                        <Col md="6">
                                          <FormGroup>
                                            <Input
                                              type="text"
                                              required="required"
                                              minLength="3"
                                              maxLength="4"
                                              pattern="[0-9]*"
                                              id="Cvv"
                                              name="Cvv"
                                              value={Cvv}
                                              valid={CvvValid}
                                              invalid={CvvValid === false}
                                              disabled={Requested}
                                              placeholder={
                                                CHECKOUT.FORM.CVV_PLACEHOLDER
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  Cvv: e.target.value,
                                                  CvvValid:
                                                    e.target.validity.valid,
                                                })
                                              }
                                              onFocus={(e) =>
                                                e
                                                  ? this.setState({
                                                      Focus: "cvc",
                                                    })
                                                  : null
                                              }
                                              className="inputs-card-data mt-4"
                                            />
                                          </FormGroup>
                                        </Col>
                                      )}
                                    </Row>
                                    <Col xs="12">
                                      <FormGroup className="checkout">
                                        {model.Cards && model.Cards.length > 0
                                          ? model.Cards.map((obj) => (
                                              <Card
                                                key={obj.Id}
                                                onClick={() =>
                                                  this.setState({
                                                    CreditCardId: obj.Id,
                                                  })
                                                }
                                                className={
                                                  CreditCardId ? "active" : ""
                                                }
                                              >
                                                <Row className="justify-content-center text-center">
                                                  <Col md="12">
                                                    <Media
                                                      className="logo"
                                                      object
                                                      src={obj.ThumbUrl}
                                                      alt="Logo"
                                                      style={{
                                                        maxWidth: "50px",
                                                        height: "28px",
                                                      }}
                                                    />
                                                  </Col>
                                                  <Col md="12">
                                                    <p className="text-muted">
                                                      {obj.LastFourDigits}
                                                    </p>
                                                  </Col>
                                                </Row>
                                              </Card>
                                            ))
                                          : null}
                                      </FormGroup>
                                    </Col>
                                  </Col>
                                  <Col
                                    md="6"
                                    xs="12"
                                    className="align-self-center"
                                  >
                                    <FormGroup>
                                      <Cards
                                        cvc={Cvv}
                                        expiry={DateValidate}
                                        focused={Focus}
                                        name={CreditCardName}
                                        number={CreditCardNumber}
                                      />
                                    </FormGroup>
                                  </Col>
                                  {!statusChangePayment && (
                                    <Col
                                      xs="12"
                                      className="d-flex justify-content-center"
                                    >
                                      <FormGroup className="text-center w-50 mb-0">
                                        {model.ShowParcelInfo
                                          ? `Pelo presente, você autoriza a Pay2B a efetuar automaticamente a cobrança ${
                                              model.RecurringUnitDescription
                                            } de ${
                                              model.InstallmentValue > 0 &&
                                              `${
                                                model.InstallmentCount > 1
                                                  ? "parcelas"
                                                  : "parcela"
                                              } no valor de ${model.InstallmentValue.BRLCurrency()}`
                                            }. Termos e condições disponíveis `
                                          : `Pelo presente, você autoriza a Pay2B a efetuar automaticamente a cobrança ${
                                              model.RecurringUnitDescription
                                            }, ${
                                              model.InstallmentValue > 0 &&
                                              `em ${model.InstallmentCount} ${
                                                model.InstallmentCount > 1
                                                  ? "parcelas"
                                                  : "parcela"
                                              }, no valor de ${model.InstallmentValue.BRLCurrency()}`
                                            }. Termos e condições disponíveis `}
                                        <a
                                          href="https://www.pay2b.com.br/termos-condicoes"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <strong style={{ color: "#23282c" }}>
                                            aqui.
                                          </strong>
                                        </a>
                                      </FormGroup>
                                    </Col>
                                  )}
                                </FormGroup>
                              ) : (
                                FinishSection &&
                                CardTonenized && (
                                  <Col xs="12">
                                    <Label>
                                      <strong>
                                        {CHECKOUT.FORM.TITLE_CARD_SECTION}
                                      </strong>
                                    </Label>
                                    <Col xs="12">
                                      <FormGroup>
                                        <Media
                                          className="logo"
                                          object
                                          src={CardTonenized.thumbUrl}
                                          alt="brand"
                                          style={{
                                            maxWidth: "50px",
                                            height: "35px",
                                            fontWeight: "700",
                                            marginRight: "3px",
                                          }}
                                        />
                                        {CardTonenized.lastFourDigits && (
                                          <span className="fw-500">
                                            {`**** **** **** ${CardTonenized.lastFourDigits}`}
                                          </span>
                                        )}
                                        <p className="fw-500">
                                          {CreditCardName.toUpperCase()}
                                        </p>
                                      </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                      <FormGroup>
                                        <p className="fw-500">
                                          {model.InstallmentValue > 0 &&
                                            `${CHECKOUT.DETAILS.SPLIT} ${
                                              model.InstallmentCount
                                            } ${
                                              model.InstallmentCount > 1
                                                ? "parcelas"
                                                : "parcela"
                                            } de ${model.InstallmentValue.BRLCurrency()}`}
                                        </p>
                                      </FormGroup>
                                    </Col>
                                  </Col>
                                )
                              )}
                              {FinishSection ? (
                                <Col xs="12">
                                  <Row className="justify-content-center text-center">
                                    {(statePostCheckout &&
                                      statePostCheckout.state !== false) ||
                                    Requested ? (
                                      <Button
                                        type="button"
                                        color="primary"
                                        className="px-4 loading-balls"
                                        style={{ width: "284px" }}
                                      >
                                        <ReactLoading
                                          color="white"
                                          height={27}
                                          width={100}
                                          type="bubbles"
                                        />
                                      </Button>
                                    ) : (
                                      <Button
                                        color="primary"
                                        className="px-4"
                                        onClick={(e) =>
                                          this.handleSubmitConfirmPayment(e)
                                        }
                                      >
                                        {CHECKOUT.FORM.FINISH_BUTTON}
                                      </Button>
                                    )}
                                  </Row>
                                  <Row className="justify-content-center text-center">
                                    {errors &&
                                      errors.map((error) => (
                                        <Label
                                          style={{
                                            color: "red",
                                            fontWeight: "700",
                                          }}
                                        >
                                          {error}
                                        </Label>
                                      ))}
                                  </Row>
                                </Col>
                              ) : (
                                ""
                              )}
                            </CardBody>
                          </Row>
                        </Card>
                        {(cardSelectionOpen || CardSection) &&
                          model.BalancePay > 0 && (
                            <>
                              <Col xs="12">
                                <Row className="justify-content-center text-center">
                                  {(statePostCheckout &&
                                    statePostCheckout.state !== false) ||
                                  Requested ? (
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="px-4 loading-balls"
                                      style={{ width: "284px" }}
                                    >
                                      <ReactLoading
                                        color="white"
                                        height={27}
                                        width={100}
                                        type="bubbles"
                                      />
                                    </Button>
                                  ) : (
                                    <Button
                                      className="px-4"
                                      style={{
                                        backgroundColor:
                                          model.ButtonConfirmBackground,
                                        borderColor:
                                          model.ButtonConfirmBackground,
                                        color: model.ButtonConfirmText,
                                      }}
                                    >
                                      {statusChangePayment
                                        ? CHECKOUT.FORM.CONFIRM
                                        : CHECKOUT.FORM.CONTINUE_BUTTON}
                                      <i className="fa fa-check" />
                                    </Button>
                                  )}
                                </Row>
                                <Row className="justify-content-center text-center">
                                  {errors &&
                                    errors.map((error) => (
                                      <Label
                                        style={{
                                          color: "red",
                                          fontWeight: "700",
                                        }}
                                      >
                                        {error}
                                      </Label>
                                    ))}
                                </Row>
                              </Col>
                            </>
                          )}
                      </Form>
                    </CardGroup>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
          <Col md="3" id="ssl-web" style={{ backgroundColor: "#f5f5f5" }}>
            <Row className="m-0 mt-5">
              <Col className="flex-column d-flex align-items-center">
                <Media
                  object
                  src={model.IconType}
                  alt="Logo"
                  className="mt-3 mb-2"
                  style={{ maxWidth: "130px" }}
                />
                <Label className="text-center border-bottom w-100 m-0 pb-3">
                  <strong style={{ color: "#58595b" }}>{model.TextType}</strong>
                </Label>
              </Col>
            </Row>
            <Row className="m-0">
              <Col md="12" className="pb-2 mt-3 mb-3">
                <Label>
                  <strong className="label-side-info">Dados da proposta</strong>
                </Label>
              </Col>
            </Row>
            {model.Cars &&
              model.Type === "AUTO" &&
              model.Cars.map((car, idx) => (
                <Row className="m-0">
                  <Col md="12" key={car.licensePlate}>
                    <span className="float-left pr-2 label-side-info">
                      {`${CHECKOUT.DETAILS.VEHICLE_PLATE} #${idx + 1}:`}
                    </span>
                    <span className="float-left fw-600 label-side-info">
                      {car.licensePlate}
                    </span>
                  </Col>
                </Row>
              ))}
            <Row className="m-0">
              <Col md="12">
                <span className="float-left pr-2 label-side-info">
                  {CHECKOUT.DETAILS.PROPOSAL_NUMBER}
                </span>
                <span
                  className={`float-left fw-600 label-side-info pb-4 w-100 ${
                    model.Type === "AUTO" && "border-bottom"
                  }`}
                >
                  {model.ProposalNumber}
                </span>
              </Col>
            </Row>
            {model.Type !== "AUTO" && (
              <Col md="12">
                <Label className="label-side-info pb-4 border-bottom w-100">
                  {model.Document.length === 10
                    ? CHECKOUT.DETAILS.CNPJ
                    : CHECKOUT.DETAILS.CPF}
                  <strong className="ml-2 label-side-info">
                    {model.Document}
                  </strong>
                </Label>
              </Col>
            )}
            {statusChangePayment ? (
              <>
                {model.ShowParcelInfo ? (
                  <>
                    <Row className="m-0">
                      <Col md="12" className="pb-2 pt-3 label-side-info">
                        <Label>
                          <strong className="label-side-info">
                            {CHECKOUT.DETAILS.VALUE}
                          </strong>
                        </Label>
                      </Col>
                    </Row>
                    <Row className="m-0">
                      <Col md="12">
                        <Label className="label-side-info pb-4 border-bottom w-100">
                          {CHECKOUT.DETAILS.BALANCE_PAY}
                          <strong className="ml-2 label-side-info">
                            {`${(
                              model.Remaining.Amount /
                              model.Remaining.Installments
                            ).BRLCurrency()}`}
                          </strong>
                        </Label>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="m-0">
                      <Col md="12" className="pb-2 pt-3 label-side-info">
                        <Label>
                          <strong className="label-side-info">
                            {CHECKOUT.DETAILS.VALUE}
                          </strong>
                        </Label>
                      </Col>
                    </Row>
                    <Row className="m-0">
                      <Col md="12">
                        <Label className="label-side-info pb-4 border-bottom w-100">
                          {CHECKOUT.DETAILS.BALANCE_PAY}
                          <strong className="ml-2 label-side-info">
                            {`${model.Remaining.Amount.BRLCurrency()} (${
                              model.Remaining.Installments
                            }x de ${(
                              model.Remaining.Amount /
                              model.Remaining.Installments
                            ).BRLCurrency()})`}
                          </strong>
                        </Label>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            ) : (
              <>
                <Row className="m-0">
                  <Col md="12" className="pb-2 pt-3 label-side-info">
                    <Label>
                      <strong className="label-side-info">
                        {CHECKOUT.DETAILS.VALUE}
                      </strong>
                    </Label>
                  </Col>
                </Row>

                {model.InstallmentValue > 0 &&
                  (model.ShowParcelInfo ? (
                    <Row className="m-0">
                      <Col md="12">
                        <Label className="label-side-info pb-4 border-bottom w-100">
                          {CHECKOUT.DETAILS.BALANCE_PAY}
                          <strong className="ml-2 label-side-info">
                            {`${model.InstallmentValue.BRLCurrency()}`}
                          </strong>
                        </Label>
                      </Col>
                    </Row>
                  ) : (
                    <Row className="m-0">
                      <Col md="12">
                        <Label className="label-side-info pb-4 border-bottom w-100">
                          {CHECKOUT.DETAILS.BALANCE_PAY}
                          <strong className="ml-2 label-side-info">
                            {`${model.BalancePay.BRLCurrency()} (${
                              model.InstallmentCount
                            }x de ${model.InstallmentValue.BRLCurrency()})`}
                          </strong>
                        </Label>
                      </Col>
                    </Row>
                  ))}
              </>
            )}
            {!model.ShowParcelInfo && (
              <Row className="m-0 mt-3">
                <Col md="12">
                  <span className="float-left pr-2 label-side-info">
                    {CHECKOUT.DETAILS.TOTAL_PRIZE}
                  </span>
                  <span className="float-left fw-600 label-side-info">
                    {total}
                  </span>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Footer isCheckout="checkout" />
      </div>
    );
  }
}

Checkout.defaultProps = {
  coords: {},
};

Checkout.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape(),
    search: PropTypes.shape(),
  }).isRequired,
  match: PropTypes.shape({ params: PropTypes.shape() }).isRequired,
  postDataCheckout: PropTypes.func.isRequired,
  postDataCard: PropTypes.func.isRequired,
  getCheckout: PropTypes.func.isRequired,
  statePostCheckout: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  listResume: PropTypes.shape({
    data: PropTypes.shape(),
    state: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
  coords: PropTypes.shape({
    coords: PropTypes.shape(),
    isGeolocationAvailable: PropTypes.bool,
    isGeolocationEnabled: PropTypes.bool,
  }),
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      postDataCheckout,
      getCheckout,
      postDataCard,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  statePostCheckout: state.checkoutState.statePostCheckout,
  listResume: state.resumeState.listResume,
});

const CheckoutGeoloc = geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Checkout);

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutGeoloc);
